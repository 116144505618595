<template>
  <div class="dashboardCard overflow-auto shadow-md">
    <div class="flex space-x-4">
      <div
        class="rounded-md flex justify-center items-center"
        :style="{ backgroundColor: color }"
        style="width:44px; height:44px; border-radius:8px"
      >
        <v-icon :icon="icon" size="6" />
      </div>
      <div>
        <div class=" text-gray-600 font-semibold text-sm">
          <slot> </slot>
        </div>
        <div class="flex items-center space-x-2 mt-3">
          <span class="font-bold text-xl">{{ subtitle }} </span>
          <span class="font-bold text-gray-600" style="font-size:17px">{{
            unit
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="ts">
import Vue from 'vue'
export default Vue.extend({
    props : {
        subtitle : null,
        icon : {
            type : String,
            default : "balance"
        },
        color : {
            type : String,
            default: "#588FF0"

        },
        unit : {
            type : String
        }
    }
})
</script>

<style scoped>
.dashboardCard {
  padding: 22px 16px;
  border-radius: 16px;
  background-color: white;
  font-size: 15px;
  line-height: 20px;
}
</style>
